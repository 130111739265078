namespace('migoa.components');

migoa.components.Gmaps = Class.create(migoa.Component, {

	id: null,
	defaultMapSettings:{
		container: null,
		lat: 41.390205,
		lang: 2.154007,
		scrollwheel: true,
		center: null,
		centerIsMarker: true,
		zoom: 15,
		streetViewControl: false,
		mapTypeControl: false,
		zoomControl: true,
		zoomControlOptions: {
			position: google.maps.ControlPosition.LEFT_CENTER
		},
		minZoom: 3,
		//maxZoom: 18,
		clickOnMarker: null,
		clickOnMap: null,
		markerOver: function(){},
		markerOut: function(){},
		styles: [{
			featureType: "poi.business",
			elementType: "labels",
			stylers: [{ visibility: "off" }]
		}],
		markerCluster: false,
		clickableIcons: false
	},
	mapSettings: null,
	markers: null,
	bounds: null,
	map: null,
	activeMarker: null,
	selectedMarker: null,
	activeCluster: null,
	mainMarker: null,
	firstMapLoad: false,
	afterEveryMapLoad: function(){},
	idleMapTimer: null,
	markerCluster: null,
	clickOnMapEnabled: true,
	infoWindow: null,


	/* constructor */
	initialize : function(id, mapSettings, options) {
		var me = this;
		
		// Merge specific options overrides
		$.extend(true, me, options);		
		
		me.id = id;
		me.markers = new Object();
		
		// Merge specific mapSettings
		me.mapSettings = $.extend({}, me.defaultMapSettings, mapSettings);		
		
		// Set default center if not set and marker		
		if (me.mapSettings.center == null){
			
			// Case no center - SerpMap			
			me.mapSettings.center = new google.maps.LatLng(me.mapSettings.lat, me.mapSettings.lang);
			
			// Init maps
			me.map = new google.maps.Map(document.getElementById(id), me.mapSettings);
		} else {						
			// Init maps
			me.map = new google.maps.Map(document.getElementById(id), me.mapSettings);
		} 	
		
		me.mapSettings.container = id;	
		
		/** setBounds **/
		if (me.bounds == null) me.bounds = me.map.getBounds();
		
		google.maps.event.addListenerOnce(me.map, 'idle', function() {
			me.firstMapLoad = true;
			
			google.maps.event.addListener(me.map, 'idle', function() {

				if (me.firstMapLoad) {
					
					if (me.idleMapTimer != null) clearTimeout(me.idleMapTimer);
					
					me.idleMapTimer = setTimeout(function(){
						me.afterEveryMapLoad();
						me.idleMapTimer = null;
					}, 300);
				}
				
				me.firstMapLoad = true;
			});
 	    });
		
		
		google.maps.event.addListener(me.map, 'click', function(event) {
			if (me.mapSettings.clickOnMap != null && me.clickOnMapEnabled) {
				me.mapSettings.clickOnMap();
				me.clearActiveMarker();
			} else {
				me.clearActiveMarker();
			}
			
			me.clickOnMapEnabled = true;
		});
		
		google.maps.event.addListener(me.map, 'idle', function() {
	        $('.gm-style').removeClass('gm-style');
		});
		
		// Info window
		me.initInfoWindow();
	},
	
	_updateMap: function(resultObject){
		var me = this;
		
		if(resultObject != undefined) {
		
			if(resultObject.generics != undefined) {
				if(resultObject.generics.zoom != undefined) {
					// New zoom
					me.map.setZoom(resultObject.generics.zoom);
				}
				if(resultObject.generics.center != undefined) {
					// New center
					me.map.setCenter(resultObject.generics.center);
				}
			}
			
			if(resultObject.apartments != undefined) {
				// Update markers
				me.updateMarkers(resultObject.apartments);
				// Update Bounds
				me.updateBounds();
			}
		}
	},
	
	updateDefaultCenter: function(defaultCenter){
		var me = this;
		me.mapSettings.center=defaultCenter;
	}, 	
	
	updateMarkers: function(markersList, options, deleteMarkers, updateMarkers){
		var me = this;
		
		if (typeof deleteMarkers === 'undefined') deleteMarkers = true;
		
		if (typeof options === 'undefined') options = {};		
		
		if (typeof updateMarkers === 'undefined') updateMarkers = false;
		
		// To keep original references
		markersList = jQuery.extend({}, markersList);
		
		for (element in me.markers){
			
			// Markers array is repeated on new list?
			if(element in markersList){

				// Yes - Remove it from list
				if (!updateMarkers && deleteMarkers) {
					
					// Remove from old list and create again
					me.markers[element].setMap(null);
					delete me.markers[element];					

				} else {
					
					// Remove from new list to keep old one
					delete markersList[element];

				}
				
				// TODO
				if (updateMarkers && me.markers[element] != null) {
					me.markers[element].setType(options.type);
					delete markersList[element];					
				}
			
			// Element is not in the new list and deletmarkers = true so set map null
			}else if(deleteMarkers) {
				
				// No - Remove old marker
				me.markers[element].setMap(null);
					
				delete me.markers[element];
			}
		}
		
		
		// Clean list of markers to add
		var cleanListOfMarkersToAdd = new Object();
		var count = 0;
		for (id in markersList){
			var options = {
				map: me.map,
				position: new google.maps.LatLng({lat: markersList[id].position.lat, lng: markersList[id].position.lng}),
				favorite: false,
				type: markersList[id].price ? "OK":"DEFAULT-NO-PRICE",
				zIndex:Number((parseInt(markersList[id].position.lat*10000000)+count)*-1)
			}

			// This marker options
			var markerOptions = $.extend( {}, options, markersList[id]);										
			
			var marker = new OAMarker(markerOptions);

			marker.addListener('click', function(e) {
				
				if (me.mapSettings.clickOnMarker !== null){
					me.mapSettings.clickOnMarker(this);
				}
				
			});
			
			marker.addListener('mouseover', function() {
				me.mapSettings.markerOver(this);
			});
			
			marker.addListener('mouseout', function() {
				me.mapSettings.markerOut(this);				
			});
			
			me.markers[id] = marker;
			cleanListOfMarkersToAdd[id] = marker;
			count++;
		}
		
		// If markers delete, remove all and redraw TODO better
		if (deleteMarkers) {
			
			me.markersArr = [];
			for (x in me.markers){
				me.markersArr.push(me.markers[x]);			
			}
			
		// If no delete then just add 
		} else {
			
			me.markersArr = [];
			for (x in cleanListOfMarkersToAdd){
				me.markersArr.push(cleanListOfMarkersToAdd[x]);			
			}
			
		}
		
	},
	
	updateBounds: function(reset){
		var me = this;
		
		// Reset bounds
		if (typeof reset === 'undefined') reset = false;
		if (reset) me.bounds = new google.maps.LatLngBounds();
		
		var fitBounds = false;
		for (id in me.markers) {
			me.bounds.extend(me.markers[id].getPosition());
			fitBounds = true;
		}	
		
		if(fitBounds) {
			me.map.fitBounds(me.bounds);
		
		// No markers use center to locate
		} else if (me.mapSettings.center != null){
			me.map.setCenter(me.mapSettings.center);
		}
		
	},
	
	createBounds: function(ne, sw) {
		var bounds = new google.maps.LatLngBounds();
		bounds.extend(ne);
		bounds.extend(sw);
		
		return bounds;
	},
	
	setActiveMarker: function(marker){
		var me = this;
		
		if (typeof marker === 'undefined') return;
		
		me.clearActiveMarker(marker);
		
		if (me.activeMarker !== null && me.activeMarker.id === marker.id) return false;
		
		me.activeMarker = marker;
		me.activeMarker.setVisited();
		me.activeMarker.setActive();
		
		return true;
	},
	
	clearActiveMarker: function(){
		var me = this;
		
		// Set marker to original state (His own state)	
		if (me.activeMarker !== null) {
			
			// Clear other active markers
			me.activeMarker.setActive(false);
		}
		
		me.activeMarker = null;
		
		me.infoWindow.close();
		
		return true;
	},
	
	setSelectedMarker: function(marker){
		var me = this;
		
		if (typeof marker === 'undefined') return;
		
		// me.clearActiveMarker(marker);
		
		if (me.selectedMarker !== null && me.selectedMarker.id === marker.id) return false;
		
		me.selectedMarker = marker;
		me.selectedMarker.setSelected();
		
		return true;
	},
	
	clearSelectedMarker: function(){
		var me = this;
		
		// Set marker to original state (His own state)	
		if (me.selectedMarker !== null) {
			
			// Clear other active markers
			me.selectedMarker.setSelected(false);
		}
		
		me.selectedMarker = null;
		
		return true;
	},	
	
	clearMarkers: function(markersArr){
		var me = this;
		
		if (typeof markersArr === 'undefined') markersArr = me.markers;
		
		for (id in markersArr){
			markersArr[id].setMap(null);
			delete markersArr[id];
		}
		
		if (me.markerCluster != null) {
			me.markerCluster.clearMarkers();
		}
	},
    
	// Get pixel coords relative to map container based on latlang position
	fromLatLngToPixelCoords: function(latLng) {
		var me = this;
		
		var topRight = me.map.getProjection().fromLatLngToPoint(me.map.getBounds().getNorthEast());
		var bottomLeft = me.map.getProjection().fromLatLngToPoint(me.map.getBounds().getSouthWest());
		var scale = Math.pow(2, me.map.getZoom());
		var worldPoint = me.map.getProjection().fromLatLngToPoint(latLng);
		return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
	},
	
	// Get LatLang position based on pixel coords relative to map container 
	fromPixelCoordsToLatLng: function(xcoor, ycoor) {
		var me = this;
		
		var ne = me.map.getBounds().getNorthEast();
		var sw = me.map.getBounds().getSouthWest();
		var projection = me.map.getProjection();
		var topRight = projection.fromLatLngToPoint(ne);
		var bottomLeft = projection.fromLatLngToPoint(sw);
		var scale = 1 << me.map.getZoom();
		var newLatlng = projection.fromPointToLatLng(new google.maps.Point(xcoor / scale + bottomLeft.x, ycoor / scale + topRight.y));
		return newLatlng;
	},
	
	// @param int pixels
	getVisibleBounds: function(left, right, top, bottom) {
		var me = this;
		if (typeof top === 'undefined') top = 0;
		if (typeof right === 'undefined') right = $('#' + MIGOA.page.components['gmap'].id).width();
		if (typeof bottom === 'undefined') bottom = $('#' + MIGOA.page.components['gmap'].id).height();
		if (typeof left === 'undefined') left = 0;
		
		// me.bounds = new google.maps.LatLngBounds();
		var bounds = new google.maps.LatLngBounds();
		bounds.extend(me.fromPixelCoordsToLatLng(left, top));
		bounds.extend(me.fromPixelCoordsToLatLng(right, bottom));
		
		return bounds;
	},
	
	getVisibleCenterLatLng: function(bounds) {
		var me = this;
		var visibleCenter = {};
		
		if (typeof bounds === 'undefined') bounds = me.getVisibleBounds(MIGOA.page.getHiddenMapWidth());
		
		visibleCenter.lat = (bounds.getNorthEast().lat() + bounds.getSouthWest().lat())/2;
		visibleCenter.lng = (bounds.getNorthEast().lng() + bounds.getSouthWest().lng())/2;
		
		// TEST
		me.updateMarkers({0: {id: 0, position: {lat: visibleCenter.lat, lng: visibleCenter.lng}, type: 'point'}}, {}, false);
		
		return new google.maps.LatLng(visibleCenter.lat, visibleCenter.lang);
		
	},
	
	/** Draw rectangle **/	
	setRectangle: function(center){
		var me = this;
		
	    var scale = 1 << me.map.getZoom();
	    var proj = me.map.getProjection();
	    var wc = proj.fromLatLngToPoint(center);
	    var bounds = new google.maps.LatLngBounds();
	    var sw = new google.maps.Point(((wc.x * scale) - 50)/ scale, ((wc.y * scale) - 50)/ scale);
	    bounds.extend(proj.fromPointToLatLng(sw));
	    var ne = new google.maps.Point(((wc.x * scale) + 50)/ scale, ((wc.y * scale) + 50)/ scale);
	    bounds.extend(proj.fromPointToLatLng(ne));
	    var opts = {
	        bounds: bounds,
	        map: me.map,
	        editable:true
	    };
	    var rect = new google.maps.Rectangle(opts);

	},
	
	/**
	 * Draw circle around
	 * @param {google.maps.LatLng} point 
	 * @param {integer} radious 
	 * @return {google.maps.Circle}
	 */
	drawCircle: function(point, radious) {
		var me = this;
		var circle = new google.maps.Circle({
			map: me.map,
            center: point,
            radius: radious,
            strokeColor: '#7bc686',
            strokeWeight: 5,
            fillOpacity: 0.2,
            fillColor: '#A8F1B3'
         });				 
		
		return circle;
		
	},
	
	/**
	 * Add marker to map
	 * @param {google.maps.LatLng} point 
	 * @param {Object} markerOptions 
	 * @return {OAMarker}
	 */
	addMarker: function(point, markerOptions) {
		var me = this;
		
		var markerDefaults = {
			position: point,
			map: me.map
		};
		
		// This marker options
		markerOptions = $.extend( {}, markerDefaults, markerOptions );							
		
		return new OAMarker(markerOptions);		
		
	},
	
	resize: function(centered) {
		var me = this;
		
		if (typeof centered === 'undefined') centered = true;
		var center = me.map.getCenter();
		
		google.maps.event.trigger(me.map, 'resize');
		
		if (centered) me.map.setCenter(center);
	},
	
	redrawMarkerType: function(marker, type){
		var me = this;
		
		if (typeof marker === 'undefined') return;

		marker.setType(type);
		
		// If the marker is in cluster redraw it
		if (me.markerCluster != null) { 
			var cluster = me.markerCluster.getClusterOfMarker(marker);
			cluster.redrawType();
		}		
		
		return true;
		
	},
	
	// Set daily price
	setDailyPrice: function(markers) {
		var me = this;

		for(marker in markers) {
			if (marker in me.markers && me.markers[marker].price)
				me.markers[marker].setText(markers[marker].price);
		}
	},
	
	setMarkersType: function(typePrice, hasDates) {
		var me = this;
		
		if (typePrice) {
			
			var styles =  {
					iconAnchor: [25, 35],
					height: 35
			}
			
			
			if (me.markerCluster != null) me.markerCluster.setStyles(styles);
			
		} else {
			
			var styles =  {
					iconAnchor: [21, 49],
					height: 49
				}
			
			if (me.markerCluster != null) me.markerCluster.setStyles(styles);			
		}
		
		if (typeof hasDates === 'undefined') hasDates = MIGOA.page.aso.hasDates; 
		
		if (me.markerCluster != null) me.markerCluster.setMarkersCounter(!hasDates);
		if (me.markerCluster != null) me.markerCluster.setShowPrice(hasDates);
		
	},
	
	initInfoWindow: function() {
		var me = this;

		me.infoWindow = new InfoBox({
			map: me.map,
			maxWidth: 320,
			pixelOffset: new google.maps.Size(-160, -50),
			alignBottom: true,
			boxStyle: { 
				background: "#FFF",
				opacity: 1,
		        width: "320px"
		    }			
		});
	}

});
