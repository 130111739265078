function OAMarker(opt_options) {
	
	opt_options.defaultOptions = {
		// IE HACK to false
		optimized: true
	}
	
	let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-primary-color');
	if (primaryColor == null) {
		primaryColor = "#23B54A";
	}
    
	this.markersType = {
		'DEFAULT-NO-PRICE': {
			icon: {
			    path: google.maps.SymbolPath.CIRCLE,
			    fillColor: primaryColor,
			    fillOpacity: 1,
			    anchor: new google.maps.Point(0,0),
			    labelOrigin: new google.maps.Point(0,-23),
			    strokeColor: "#fff",
			    strokeOpacity: 1,
			    strokeWeight: 1,
				scale: 10
			}
		},
		'OK': {
			label: {
				color: "#fff",
				fontFamily: "Lato",
				fontSize: "12px",
				fontWeight: "700",
				text: "-"
			},
			icon: {
			    path: "M-19,-35 h 38 q 5 0 5 5 v 15 q 0 5 -5 5 h -14 l -5 8 l -5 -8 h -14 q -5 0 -5 -5 v -15 q 0 -5 5 -5",
			    fillColor: primaryColor,
			    fillOpacity: 1,
			    anchor: new google.maps.Point(0,0),
			    labelOrigin: new google.maps.Point(0,-23),
			    strokeColor: "#fff",
			    strokeOpacity: 1,
			    strokeWeight: 1,
			    scale: 1,
			}
		},
		'KO': {
			label: {
				color: "#FFF",
				fontFamily: "Lato",
				fontSize: "12px",
				fontWeight: "700",
				text: "-"				
			},
			icon: {
				path: "M-19,-35 h 38 q 5 0 5 5 v 15 q 0 5 -5 5 h -14 l -5 8 l -5 -8 h -14 q -5 0 -5 -5 v -15 q 0 -5 5 -5",
			    fillColor: '#424F5F',
			    fillOpacity: 1,
			    anchor: new google.maps.Point(0,0),
			    labelOrigin: new google.maps.Point(0,-23),
			    strokeColor: "#fff",
			    strokeOpacity: 1,
			    strokeWeight: 1,
			    scale: 1,
			}			
		}		
	}
	
	opt_options = $.extend(true, {}, opt_options.defaultOptions, opt_options);
	
	google.maps.Marker.apply(this, arguments);
};

OAMarker.prototype = new google.maps.Marker();
window['OAMarker'] = OAMarker;

/**
 * Set marker text
 *
 */
OAMarker.prototype.setText = function(text) {
	var label = this.getLabel();
	if (typeof text === 'undefined') return false;		
	
	label.text = text;	
	this.redraw();
};

OAMarker.prototype['setText'] = OAMarker.prototype.setText;

/**
 * Set marker type
 *
 */
OAMarker.prototype.setType = function(type) {
	this.type = typeof type == 'undefined' ? 'DEFAULT-NO-PRICE' : type;
	this.redraw();
};

OAMarker.prototype['setType'] = OAMarker.prototype.setType;

/**
 * Set marker active
 *
 */

OAMarker.prototype.setActive = function(active) {
	this.active = typeof active == 'undefined' ? true : active;
	this.redraw();
	if(this.active) {
		this.backupZIndex = this.getZIndex();
		this.setZIndex(2147483647);
	} else {
		this.setZIndex(this.backupZIndex);
	}
};

OAMarker.prototype['setActive'] = OAMarker.prototype.setActive;

/**
 * Set marker visited
 *
 */

OAMarker.prototype.setVisited = function(visited) {
	this.visited = typeof visited == 'undefined' ? true : visited;
	this.redraw();
};

OAMarker.prototype['setVisited'] = OAMarker.prototype.setVisited;

/**
 * Set marker selected
 *
 */

OAMarker.prototype.setSelected = function(selected) {
	this.selected = typeof selected == 'undefined' ? true : selected;
	this.redraw();
	if(this.selected) {
		this.backupZIndex = this.getZIndex();
		this.setZIndex(2147483647);
	} else {
		this.setZIndex(this.backupZIndex);
	}
};

OAMarker.prototype['setSelected'] = OAMarker.prototype.setSelected;

/*
 * Redraw marker icon
 */

OAMarker.prototype.redraw = function() {
	
	if (typeof this.type === 'undefined' || this.type === 'DEFAULT' || this.type == null) return false;
	
	var label = this.markersType[this.type].label; 
	var icon = this.markersType[this.type].icon;
	
	if (this.getLabel() && this.getLabel().text) {

		if (label == null) {
			label = {};
		}

		label.text = this.getLabel().text;
		
		// Increase label size depending on value
		if (label.text.length > 5) {
			var inc = label.text.length - 5;
			icon.path = "M-" + (19 + inc) + ",-35 h " + (38 + inc*2) + " q 5 0 5 5 v 15 q 0 5 -5 5 h -" + (14 + inc) + " l -5 8 l -5 -8 h -" + (14 + inc) + " q -5 0 -5 -5 v -15 q 0 -5 5 -5";
		}
	}

	if (this.visited && this.type == "OK") {
		if (label != null) {
			label = $.extend({}, label, {color: "#FFF"});
		}
		if (icon != null) {
			icon = $.extend({}, icon, {fillColor: '#91DAA4'});
		}		
					
	} else if (this.visited && this.type == "KO") {
		if (label != null) {
			label = $.extend({}, label, {color: "#424f5f"});
		}
		if (icon != null) {
			icon = $.extend({}, icon, {fillColor: '#B3B9BF'});
		}		
		
	}	
	
	if (this.selected || this.active) {
		if (label != null) {
			label = $.extend({}, label, {color: "#424f5f"});
		}
		if (icon != null) {
			icon = $.extend({}, icon, {fillColor: '#ffdc40'});
		}
	}
	
	this.setOptions({
		icon: icon,
		label: label
	});
};

OAMarker.prototype['redraw'] = OAMarker.prototype.redraw;